/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from "react";
//import styling
import './styles.css'
// @mui material components
import Grid from "@mui/material/Grid";
import { Typography } from '@mui/material'
import { CardMedia } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
// Authentication layout components
import PageLayout from 'examples/LayoutContainers/PageLayout'

//import useDispatch
import { useDispatch } from 'react-redux';

//import firebase auth
import 'firebase/compat/auth';

//import functions
import getSettings from "./functions/getSettings";
import getCompaign from "./functions/getCompaign";
import getGyms from "./functions/getGyms";
//import components
import { Navbar } from "layouts/components";
import Banner from "layouts/components/banner";
import WhatsappSupport from "layouts/components/whatsappSupport";
import VideoPlayer from "react-video-js-player"
import SwiperComponent from "layouts/components/swiper/swiper";
import SkeletonTypography from "layouts/components/skeleton";
//import images
import noResultImg from "../../assets/images/no-results.png"
import poster from "../../assets/images/poster-video.png"

//import models
import ViewGymsAccess from "./models/viewGymsAccess";
import { useNavigate } from 'react-router-dom';


//import slide styling 
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import React from 'react';
import Faqs from "layouts/components/faqs";


function Basic({setIndex}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeCampaign, setactiveCampaign] = useState(true)
  const [settings, setSettings] = useState({})
  const [campaign, setCampaign] = useState({})
  const [colorHelpText, setColorHelpText] = useState("#2699FB")
  const [colorButton, setColorButton] = useState("dark")
  const [gyms, setGyms] = useState([])
  const [openGymsModel, setOpenGymsModel] = useState(false)
  const [faqsData, setFaqsData] = useState([])
  const [loading, setLoading] = useState(true)

  const onClickButton = ()=>{
    navigate('/info');
  }

  function daysToMonths(days) {
    const daysInMonth = 30; 
    const months = Math.floor(days / daysInMonth);
    return months;
  }
  useEffect(() =>{
    getSettings(setSettings, setLoading, setFaqsData, dispatch)
    getGyms(setGyms)
  }, [])
  useEffect(() =>{
    if (campaign?.mainPage) {
       setLoading(false)
    }

  }, [campaign])
  useEffect(() =>{
    if (settings?.campaign?.activeCampaign) {
      getCompaign(settings?.campaign?.activeCampaign, setCampaign, dispatch)
      setactiveCampaign(true)
    }else{
      setactiveCampaign(false)
    //  setLoading(false)
    }
  }, [settings])

  useEffect(() => {
    const intervalId = setInterval(() => {
      setColorButton((prevColorButton) => (prevColorButton === 'dark' ? 'error' : 'dark'));
    }, 2000); 
    
    return () => clearInterval(intervalId);
  }, []);


  return (
    <PageLayout>
      {loading && 
        <Grid
        container
        spacing={1}
        position="fixed"
        justifyContent='center'
        alignItems='center'
        zIndex="1000"

      >
         <Grid item xs={12} sm={9} md={5} lg={4} xl={3} marginTop={0}>
         <SkeletonTypography loading={loading}/>
         </Grid>
        
      </Grid>
      }
      {activeCampaign && campaign && !loading &&
      <>

      <Grid
          container
          spacing={1}
          position="fixed"
          justifyContent='center'
          alignItems='center'
          zIndex="1000"
 
        >
           <Grid item xs={12} sm={9} md={5} lg={4} xl={3} marginTop={0}>
           <Banner text={campaign?.mainPage?.p1} buttonClick={onClickButton}/>
           </Grid>
          
        </Grid>

               <MDBox
               px={1}
               width="100%"
               bgColor="#fafafa"
             
               height="100vh"
               style={{
                padding: 30,
                overflowY: 'auto', 
                position: 'relative',  
              }}

               alignItems="center"
               mx='auto'
             >
              
              <Grid
          container
          spacing={1}
          justifyContent='center'
          alignItems='center'
         
 
        >
          <Grid item xs={12} sm={9} md={5} lg={4} xl={3} marginTop={0}>
          
              <Navbar />
              <WhatsappSupport linkWtsp={settings?.campaign?.customerServiceWhatsapp}/>
              <MDBox textAlign="center" marginTop={1} sx={{borderRadius: "10px"}} >
                     <Typography color={"#000000"} variant="h3" fontWeight="bold" textTransform="none" >
                       {campaign?.mainPage?.h1}
                     </Typography>
                   </MDBox>
                   
                   <MDBox textAlign="center" marginTop={1} width="100%">
                     <p onClick={()=>setOpenGymsModel(true)} style={{ fontSize: "14px", color: colorHelpText, fontWeight: "bold", textDecoration:"underline", cursor:'pointer'  }}>Voir la liste complète des partenaires</p>
                    </MDBox>
                  
                   <MDBox textAlign="left" marginTop={2}>
                     
                           <SwiperComponent packs={campaign?.packs} daysToMonths={daysToMonths} buttonClick={onClickButton}/>

                   </MDBox>


               <MDBox marginTop={1} textAlign='center' width="100%">
                 <MDButton
                   variant="outlined"
                   fullWidth
                   onClick={onClickButton}
                   color={colorButton}
                 >
                  <Typography color={colorButton} variant="h3" fontWeight="bold" textTransform="none">
                  {campaign?.mainPage?.button?.title}
                     </Typography>
                   
                 </MDButton>
               </MDBox>
               <MDBox textAlign="center" marginTop={1} width="100%">
                 <p style={{ fontSize: "10px", color: "gray" }}>Avance remboursable.</p>
                 <p style={{ fontSize: "10px", color: "gray" }}>Facilités de paiement.</p>
               </MDBox>
               <MDBox textAlign="center" marginTop={0}  style={{padding:13 }}>
                    {campaign?.mainPage?.video && 
                    <VideoPlayer
                    src={campaign?.mainPage?.video}
                    poster={poster}
                    />}
                   </MDBox>
              <Faqs faqsData={faqsData} title="Questions fréquemment posées"/>
       
          </Grid>
        </Grid>
            
             </MDBox></>
      
             
      }
      {!activeCampaign  && !loading && 
       <MDBox
       px={1}
       width="100vw"
       bgColor="white"
       height="100vh"
       display="flex"
       flexDirection="column"
       alignItems="center"
       justifyContent="center"
     >
       <Grid container justifyContent="center" alignItems="center">
         <Grid item>
           <MDBox textAlign="center">
             <Typography color={"#000000"} variant="button" fontWeight="bold" textTransform="none">
               Oups, aucune promo n'est disponible actuellement.
             </Typography>
           </MDBox>
           <MDBox textAlign="center" marginTop={2}>
             <div className="iconBack">
               {/* Your icon content goes here */}
               <img className="iconImg" src={noResultImg} alt="image description" />
             </div>
           </MDBox>
         </Grid>
       </Grid>
     </MDBox>
     
      }

    <ViewGymsAccess open={openGymsModel} setOpen={setOpenGymsModel} gyms={gyms} />
    </PageLayout>
  );
}

export default Basic;
